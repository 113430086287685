import React, {Component} from 'react';
import axios from "axios";
import history from "../History";
import formateTime from "./formateTime";
class Finished extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.location.state
    }

    componentDidMount() {
        axios.post('https://skill-test-api.mark2win.com/api/testuserquestionin', this.state)
            .then(res => {
                console.log(res.data)
            }).catch(err => {
            console.log(err)
        })
    }

    backToHome = () => {
        history.push({pathname: "/home", state: this.state.userId})
    }

    render() {
        return (
            <div className="container mt-3">
                <div className="body mb-3 font-weight-bold text-center">
                    <p>Test Finished!</p>
                    <p>Your socre: <span className="text-warning">{this.state.score}</span></p>
                    <p>Total Time: {formateTime(this.state.time)}</p>
                </div>
                <div className="body">

                    {/*<p style={{ textAlign: 'center'}}>*/}
                    {/*    Thank you very much for using our test system, please scan the QR code to contact our sales to give you the correct answer.*/}
                    {/*</p>*/}
                    {/*<p style={{ textAlign: 'center'}}>*/}
                    {/*    <img src="/img/QR2020041923103514.png" alt="QRCode"/>*/}
                    {/*</p>*/}
                    <div className="card-header text-center">Correct Answers</div>
                    <div className="body">
                        <ul className="list-group list-group-item-secondary">
                            {this.state.questions.map((answer, index) => {
                                return (
                                    <li style={{cursor: "pointer"}}
                                        className="list-group-item"
                                        key={answer.id}><p>{answer.question}</p>
                                        <p
                                            className="text-success">Correct Answer: {answer.correct}</p>
                                        <p className={answer.correct === this.state.userChoice[index] ? "text-success" : "text-danger"}>Your
                                            Answered: {this.state.userChoice[index]}
                                            {answer.correct === this.state.userChoice[index] ?
                                                <img src='/img/yes1.png' width="20xp" className="ml-3" alt="yes"/> :
                                                <img src='/img/no1.png' width="20px" className="ml-3" alt="no"/>}
                                        </p>

                                    </li>)
                            })}

                        </ul>
                    </div>

                </div>
                <div className="body text-center mt-3 mb-3">
                    <div className="body text-center">
                        <button className="btn btn-warning mb-3" onClick={this.backToHome}>Back</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Finished;
