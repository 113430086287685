import React, {Component} from 'react';
import axios from 'axios';
import './quizcss.css';
import history from "../History";
import formateTime from "./formateTime";

class Quiz extends Component {

    state = {
        userId: this.props?.location?.query?.userId,
        userAnswer: null,
        currentQuestion: 0,
        questions: [],
        isLoading: false,
        testFinished: false,
        score: 0,
        disabled: true,
        userChoice: [],
        career: this.props?.location?.query?.career,
        time: 0,
        timeOn: true

    }

    componentDidMount() {
        if (this.state.userId === undefined) {
            history.push("/")
        }
        // const course_id = this.props?.location?.query?.course
        // const categroy_id = this.props?.location?.query?.category

        axios.get(`https://skill-test-api.mark2win.com/api/careertest/${this.state.career}`)
            .then(res => {
                this.setState({
                    questions: res.data,
                    isLoading: true
                })
            }).catch(err => {
            this.setState({
                isLoading: true
            })
            console.log(err)
        })
        this.timeRecorder()
    }

    timeRecorder = () => {
        if (this.state.timeOn) {
            this.timer = setInterval(() => {
                this.setState({time: ++this.state.time})
            }, 1000)
        } else {
            clearInterval(this.timer);
        }

    }

    nextQuestion = () => {
        if (this.state.currentQuestion < this.state.questions.length - 1) {
            this.setState({
                currentQuestion: this.state.currentQuestion + 1,
                disabled: true
            })
        }
        this.checkAnswer()
    }

    selectasnwer = answer => {
        this.setState({
            userAnswer: answer,
            disabled: false,

        })
    }

    finished = () => {
        this.checkAnswer()
        // console.log("this is finish")
        if (this.state.currentQuestion === this.state.questions.length - 1) {
            this.setState({
                testFinished: true,
                timeOn: false,
            })
        }
        this.timeRecorder()
    }

    checkAnswer = () => {
        this.setState({
            userChoice: [...this.state.userChoice, this.state.userAnswer]
        })
        if (this.state.userAnswer === this.state?.questions[this.state.currentQuestion]?.correct) {
            this.setState({
                score: this.state.score + 1,

            })
            // console.log('correct answer', this.state.userAnswer)
        }
    }

    backToHome = () => {
        history.push({pathname: "/home", state: this.state.userId})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.testFinished) {
            history.push({pathname: "/finish", state: this.state})
        }
    }

    render() {
        let time = formateTime(this.state.time);

        if (this.state.isLoading === false) {
            return <div style={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
                'justifyContent': 'center',
                'height': 'auto',
                'width': '100%',
                'textAlign': 'center'
            }}>
                <div><img src="/img/loading.gif" style={{'width': '100%', 'height': '100%'}}
                          alt="loading"/></div>
            </div>
        }

        if (this.state.questions.length > 0) {
            return (
                <div className="container mt-5">
                    <div className="card text-white bg-secondary ">
                        <div className="card-header font-weight-bold">{this.state?.questions[this.state.currentQuestion]?.question}</div>
                        <div className="card-body">
                            <ul className="list-group list-group-item-secondary">
                                {this.state?.questions[this.state.currentQuestion]?.answers?.map(answer => {
                                    return (
                                        <li onClick={() => this.selectasnwer(answer)} style={{cursor: "pointer"}}
                                            className={`list-group-item ${this.state.userAnswer === answer ? "list-group-item-info" : null}`}
                                            key={answer}>{answer}</li>)
                                })}
                            </ul>
                        </div>

                    </div>
                    <div className="row justify-content-between mt-3">
                        <div className="col-4">
                            {this.state.currentQuestion < this.state.questions.length - 1 &&
                            <button disabled={this.state.disabled}
                                    className="btn btn-outline-info justify-content-center"
                                    onClick={this.nextQuestion}>Next
                            </button>
                            }

                            {this.state.currentQuestion === this.state.questions.length - 1 &&
                            <button disabled={this.state.disabled}
                                    className="btn btn-outline-primary justify-content-center"
                                    onClick={this.finished}>Finish
                            </button>
                            }
                        </div>
                        <div className="col-2">
                            <img src="/img/timer.gif" alt="" width="30px" className="float-right"/>

                        </div>
                        <div className="col-2 align-items-center">

                           <div>{time}</div>
                        </div>
                        <div className="col-4">
                        <span
                            className="float-right font-bold">{this.state.currentQuestion + 1}/{this.state.questions.length}</span>
                        </div>

                    </div>


                </div>
            );
        } else {
            return (
                <div className="container mt-5">
                    <div className="body text-center">
                        <p>Sorry! no test for this category</p>
                    </div>
                    <div className="body text-center">
                        <button className="btn btn-warning" onClick={this.backToHome}>Back</button>
                    </div>

                </div>
            )
        }

    }
}

export default Quiz;
