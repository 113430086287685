import React, {Component} from 'react';
import axios from "axios";
import history from '../History';
import {Link} from 'react-router-dom';

class Homepage extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.state === undefined) {
            history.push("/")
        }
    }

    state = {
        career: [],
        userId: this.props.location.state,
        isLoading: false,
    }

    componentDidMount() {
        axios.get('https://skill-test-api.mark2win.com/api/career')
            .then(res => {
                this.setState({career: res.data, isLoading: true})
            }).catch(err => {
            this.setState({
                isLoading: true
            })
            console.log(err)
        })
    }

    render() {
        if (this.state.isLoading === false) {
            return <div style={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
                'justifyContent': 'center',
                'height': 'auto',
                'width': '100%',
                'textAlign': 'center'
            }}>
                <div><img src="/img/loading.gif" style={{'width': '100%', 'height': '100%'}}
                          alt="loading"/></div>
            </div>
        }
        return (
            <div className="container mt-5">
                <div className="card text-white bg-secondary mt-5">
                <div className="card-header text-center"><p>Welcome <span className="text-warning">{this.state.userId.userName}</span> to Mark2Win Test System</p></div>

                    <div className="card-body align-items-center p-3">
                    {this.state.career.map((career, index) => {
                        return (
                            <div key={career.id}>




                                    <div className="row justify-content-center">
                                        <Link to={{
                                            pathname: `/quiz`,
                                            query: {
                                                userId: this.state.userId,
                                                career: career.id,
                                            }
                                        }}>
                                            <button className="btn btn-info m-3" style={{width: "200px"}}>{career.name}
                                            </button>
                                        </Link>
                                    </div>

                                </div>

                        )

                    })}
                    </div>
                </div>

            </div>
        );
    }
}

export default Homepage;
