import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import Homepage from "./components/Homepage";
import Quiz from "./components/quiz";
import Login from "./components/login";
import Default from "./components/Default";
import 'bootstrap/dist/css/bootstrap.min.css';
import history from "./History";
import './App.css';
import Finished from "./components/finished";

function App() {
    return (
        <div className="container mt-3">

            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path='/home' component={Homepage} />
                    <Route path='/quiz' component={Quiz} />
                    <Route path="/finish" component={Finished}></Route>
                    <Route component={Default} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
