import React, {Component} from 'react';
import axios from 'axios';
import history from '../History';

class Login extends Component {
    state = {
        testcode: "",
        isLoading: true,
        placeText: 'Test Code',
        placeColor: ''
    }

    changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    submitHandler = event => {
        this.setState({isLoading: false})
        event.preventDefault();
        // console.log(this.state)
        axios.post('https://skill-test-api.mark2win.com/api/testcode', this.state)
            .then(res => {
                this.setState({isLoading: true})
                // console.log(res.data)
                if (res.data.loginkey === 'ok') {
                    this.setState({placeText: 'Test Code', placeColor: ''})
                    history.push({pathname: "/home", state: res.data})
                } else {
                    this.setState({placeText: 'Code not found', placeColor: 'text-danger'})
                }

            }).catch(err => {
            console.log(err)
            this.setState({isLoading: true})
        })

    }

    render() {
        if (this.state.isLoading === false) {
            return <div style={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
                'justifyContent': 'center',
                'height': 'auto',
                'width': '100%',
                'textAlign': 'center'
            }}>
                <div><img src="/img/loading.gif" style={{'width': '100%', 'height': '100%'}}
                          alt="loading"/></div>
            </div>
        }
        const {testcode, placeText, placeColor} = this.state


        return (
            <div className="col align-items-center">
                <form onSubmit={this.submitHandler}>
                    <div className="mt-3 text-center">
                        <img src="/img/mark2win.png" width="200rem" alt="Mark2Win" className="mt-3"/>
                    </div>
                    <div className="col-10 mx-auto col-lg-4 mt-5 text-center">
                        <label htmlFor="inputTestcode" className={placeColor}>{placeText}</label>
                        <input type="text" name="testcode" id="inputTestcode" className="form-control"
                               placeholder="Test Code"
                               required autoFocus value={testcode} onChange={this.changeHandler} autoComplete="off"/>
                    </div>
                    <div className="col-10 mx-auto col-lg-4 mt-3">
                        <button className="btn btn-outline-info btn-block" type="submit">Login in</button>
                    </div>
                    <div className="text-center mt-5">
                        <img src="/img/QR2020041923103514.jpg" alt="QRCode"/>
                        <p className="mt-3">Please scan the QR code to apply for the test code</p>
                    </div>
                </form>

            </div>
        );
    }
}

export default Login;
