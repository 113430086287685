import React, {Component} from 'react';

class Default extends Component {
    render() {
        return (
            <div>
               Sorry! No information
            </div>
        );
    }
}

export default Default;
